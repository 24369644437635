
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        centered: Boolean
    }
})
export default class BodyRow extends Vue {

    centered!: boolean;

}
