
import { Options, Vue } from 'vue-class-component';

// Exposed by SoundCloud API
// eslint-disable-next-line
declare const SC: any;

@Options({
    props: {
        curTrack: Number,
        autoPlay: Boolean,
    },
    emits: [
        'play', 'pause', 'finish'
    ],
})
export default class AudioPlayer extends Vue {

    private curTrack = 0;
    private autoPlay = false;

    // eslint-disable-next-line
    private widget: any;

    private playlist = "playlists/1163977417";

    private tracks: Array<string> = [
        '930767029', // A
        '930767023', // B
        '930767020', // C
        '930767017', // D
        '930767014', // E
        '930767011', // F
        '930767008', // G
        '930767005', // H
        '930767002', // I
        '930766876', // J
        '930766873', // K
        '930766870', // L
        '930766864', // M
        '930766858', // N
        '930766855', // O
        '930766852', // P
        '930766849', // Q
        '930766846', // R
        '930766843', // S
        '930766840', // T
        '930766834', // U
        '930766831', // V
        '930766828', // W
        '930766825', // X
        '930766822', // Y
        '930766819', // Z
    ];

    mounted()
    {
        this.$watch('curTrack', this.onCurChange);

        const widgetIframe = document.getElementById('sc-player');

        // eslint-disable-next-line
        // const SC = (window as any).SC;
        const widget = SC.Widget(widgetIframe);
        this.widget = widget;

        widget.bind(SC.Widget.Events.READY, () => {
            // console.log('SC Widget is ready');
        });

        widget.bind(SC.Widget.Events.PLAY , () => {
            console.log('Widget play');
            this.$emit('play');

            this.$emitter.emit('globalPlay', 'AlphabetTool');
        });

        widget.bind(SC.Widget.Events.PAUSE , () => {
            console.log('Widget pause');
            this.$emit('pause');
        });

        widget.bind(SC.Widget.Events.FINISH , () => {
            console.log('Widget finished');
            if(!this.autoPlay)
            {
                this.widget.skip(this.curTrack);
                this.widget.pause();
            }
            this.$emit('finish');
        });

        widget.bind(SC.Widget.Events.SEEK , () => {
            console.log('Widget seek');
            this.widget.getCurrentSoundIndex((index: any) => {
                // if(index !== this.curTrack)
                // {
                //     this.curTrack = index;
                // }
            });
        });

        // Listen to globalPlay events, and pause if the event is not us
        this.$emitter.on('globalPlay', this.handleGlobalPlay);

        // const src = `https://api.soundcloud.com/playlists/1163977417&color=%2323C3FF`;

        // widget.load(src, {
        //     'show_artwork': false,
        //     'show_user': false,
        //     'show_teaser': false,
        //     'auto_play': true,
        // });
    }

    public beforeUnmount()
    {
        this.$emitter.off('globalPlay', this.handleGlobalPlay);
    }

    private handleGlobalPlay(target: string)
    {
        // console.log(`handleGlobalPlay ${target}`);
        if(target !== 'AlphabetTool')
        {
            // Pause self
            this.pause();
        }
    }

    public play()
    {
        this.widget.play();
    }

    public pause()
    {
        this.widget.pause();
    }

    private onCurChange()
    {
        const trackId = this.tracks[this.curTrack];

        this.widget.skip(this.curTrack);
        if(!this.autoPlay)
        {
            this.widget.pause();
        }

        // const src = `https://api.soundcloud.com/tracks/${trackId}&color=%2323C3FF`;


        // this.widget.load(src, {
        //     'show_artwork': false,
        //     'show_user': false,
        //     // 'auto_play': this.autoPlay,
        //     'show_teaser': false,
        //     'callback': () => {
        //         console.log('Within callback');
        //         console.log(this.autoPlay);
        //         if(this.autoPlay)
        //         {
        //             console.log('Trying to autoplay');
        //             setTimeout(() => {
        //                 this.play();
        //             }, 1000);
        //         }
        //     }
        // });

        // console.log(`Autoplay: ${this.autoPlay}`);
    }

    get initialSrc()
    {
        const trackId = this.tracks[0];
        return `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1163977417&amp;color=%2323C3FF&amp;show_user=false&amp;show_playcount=false&amp;show_artwork=false&amp;auto_play=false&amp;show_teaser=false`;
        // return `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${trackId}&amp;color=%2323C3FF&amp;show_user=false&amp;show_playcount=false&amp;show_artwork=false&amp;auto_play=false&amp;show_teaser=false`;
    }

}
