
import { Options, Vue } from 'vue-class-component';

import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import Organisations from '../components/blocks/Organisations.vue';
import BodyRow from '../components/BodyRow.vue';
import LeaderVideos from '../components/blocks/LeaderVideos.vue';
import HelpRecovery from '../components/blocks/HelpRecovery.vue';
import HowToStop from '../components/blocks/HowToStop.vue';
import HelpingYou from '../components/blocks/HelpingYou.vue';
import PlayerLed from '../components/blocks/PlayerLed.vue';
import Contact from '../components/blocks/Contact.vue';
import AlphabetTool from '../components/Alphabet/AlphabetTool.vue';
import PortalLink from '../components/blocks/PortalLink.vue';

@Options({
  props: {
    msg: String
  },
  components: {
      BodyRow, Header, LeaderVideos, Footer, Organisations, HelpRecovery, HowToStop, HelpingYou, PlayerLed, Contact, AlphabetTool, PortalLink
  }
})
export default class MainTemplate extends Vue {

    public mounted()
    {
        // Check if an anchor was supplied
        const anchor = this.$route.hash;

        if(anchor)
        {
            const aStr = anchor as string;
            if(aStr.length == 2)
            {
                const letter = aStr.substr(1, 1);

                const doc: Document = document;
                if(doc)
                {
                    setTimeout(() => {
                        // @ts-ignore: Unreachable code error
                        doc.querySelector('#alphabet-tool').scrollIntoView({
                            behavior: 'smooth'
                        });
                    }, 500);

                    const tool: AlphabetTool = this.$refs['alphabetTool'] as AlphabetTool;
                    tool.setLetter(letter);
                }
            }
        }
    }

    private handleNavigate(anchor: string)
    {
        this.$router.push({ name: 'home', hash: `#${anchor}`});
        if(anchor && document)
        {
            const target = `#${anchor}`;
            const doc: Document = document;
            if(doc)
            {
                // @ts-ignore: Unreachable code error
                doc.querySelector(target).scrollIntoView({
                    behavior: 'smooth'
                });

                // Check if this is one of the leader videos
                // #getting-started #needing-help
                const videos = ['getting-started', 'needing-help'];
                if(videos.includes(anchor))
                {
                    const leaderVideos = this.$refs.leaderVideos as LeaderVideos;
                    leaderVideos.showVideo(videos.indexOf(anchor)+1);
                }
            }
        }
    }
}
