<template>
    <div class="menu" :class="{ 'menu--move':!show, 'menu--hidden': hidden }">
        <div class="menu__close">
            <a href="#" @click.prevent="closeMenu" class="menu__close-link">
                <img  v-svg-inline src="@/assets/app-images/svgs/x.svg" />
            </a>
        </div>

        <ul class="menu__items">
            <li class="menu__item"><a @click.prevent="handleNav('getting-started')" href="#getting-started">Getting Started</a></li>
            <li class="menu__item"><a @click.prevent="handleNav('needing-help')" href="#needing-help">Needing Help</a></li>
            <li class="menu__item"><a @click.prevent="handleNav('alphabet-tool')" href="#alphabet-tool">Alphabet Tool</a></li>
            <li class="menu__item"><a @click.prevent="handleNav('stop-gambling')" href="#stop-gambling">Stop Gambling</a></li>
            <li class="menu__item"><a @click.prevent="handleNav('self-help')" href="#self-help">Self Help</a></li>
            <li class="menu__item"><a @click.prevent="handleNav('more-help')" href="#more-help">More Help</a></li>
            <li class="menu__item"><a @click.prevent="handleNav('contact')" href="#contact">Contact Info</a></li>
        </ul>

        <h2 class="menu__helpline">24Hr National Helpline<br />0800 138 6518</h2>

        <Socials twitter facebook youtube class="menu__socials" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Socials from "@/components/Socials.vue";

@Options({
    props: {
        show: Boolean,
    },
    components: {
        Socials
    },
    emits: [
        'close', 'navigate'
    ],
})
export default class Menu extends Vue {

    show = false;
    hidden = true;

    mounted()
    {
        this.$watch('show', (show: boolean) => {
            if(show) this.hidden = false;
        });
    }

    public closeMenu()
    {
        this.hidden = true;

        setTimeout(() => {
            this.$emit('close');
        }, 500);
    }

    private handleNav(anchor: string)
    {
        this.closeMenu()

        setTimeout(() => {
            this.$emit('navigate', anchor);
        }, 300);
    }

}
</script>