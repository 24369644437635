<template>
    <div class="player-led">
        <h3 class="player-led__header">The <strong>First Player-Led</strong> Safer Gambling Initiative</h3>
        <p class="player-led__body">‘Raising Standards of Player Protection to reduce the <strong>HARMS</strong> caused by gambling’</p>

        <slot></slot>

        <p class="player-led__produced-by">Designed and Produced by<br /><strong>PGSolutions Group</strong></p>
        <div class="player-led__producers-wrapper">
            <div class="player-led__producers">
                <p class="player-led__producers-title">PGSolutions</p>
                <p class="player-led__producers-body">{{ $t('dbyloi_site_contact_body_2') }}</p>
            </div>
            <p class="player-led__ara"><a href="https://www.recovery4all.co.uk" target="_new">Recovery4all.co.uk</a></p>
            <p class="player-led__bct"><a href="https://www.beaconcounsellingtrust.co.uk" target="_new">Beacon<br />CounsellingTrust.co.uk</a></p>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PlayerLed extends Vue {

}
</script>