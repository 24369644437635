
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class Organisations extends Vue {

    data()
    {
        return {
            orgs: [
                { title: this.$t('dbyloi_site_organisations_org_1_name'), copy: this.$t('dbyloi_site_organisations_org_1_desc'), class: "gs",  icon: "gamstop-colour.svg",                        contactLink: "gamstop.co.uk" },
                { title: this.$t('dbyloi_site_organisations_org_2_name'), copy: this.$t('dbyloi_site_organisations_org_2_desc'), class: "gb",  icon: "gamban-colour.svg",                         contactLink: "gamban.com" },
                { title: this.$t('dbyloi_site_organisations_org_3_name'), copy: this.$t('dbyloi_site_organisations_org_3_desc'), class: "ngh", icon: "the-national-gambling-helpline-colour.svg", contactText: "Freephone<br />0808 8020 133" },
                { title: this.$t('dbyloi_site_organisations_org_4_name'), copy: this.$t('dbyloi_site_organisations_org_4_desc'), class: "gc",  icon: "gamcare-colour.svg",                        contactLink: "gamcare.org.uk" },
                { title: this.$t('dbyloi_site_organisations_org_5_name'), copy: this.$t('dbyloi_site_organisations_org_5_desc'), class: "ga",  icon: "gamblers-anonymous-colour.svg",             contactText: "Meeting Weekly" },
                { title: this.$t('dbyloi_site_organisations_org_6_name'), copy: this.$t('dbyloi_site_organisations_org_6_desc'), class: "s",   icon: "samaritans-colour.svg",                     contactLink: "samaritans.org", contactText: "0330 094 0322" },
            ]
        }
    }

    getImage(icon: string)
    {
        return require(`@/assets/app-images/svgs/${icon}`);
    }

}
