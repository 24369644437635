
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalProduct extends Vue {

}
