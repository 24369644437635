<template>
    <div class="portal-body__product">
        <h1 v-html="tmd('dbyloi_generic_portal_header_product')"></h1>
        <p v-html="tmd('dbyloi_portal_product_body_1')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_2')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_3')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_4')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_5')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_6')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_7')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_8')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_9')"></p>

        <ol>
            <li v-html="tmd('dbyloi_portal_product_objective_1')"></li>
            <li v-html="tmd('dbyloi_portal_product_objective_2')"></li>
            <li v-html="tmd('dbyloi_portal_product_objective_3')"></li>
            <li v-html="tmd('dbyloi_portal_product_objective_4')"></li>
            <li v-html="tmd('dbyloi_portal_product_objective_5')"></li>
            <li v-html="tmd('dbyloi_portal_product_objective_6')"></li>
        </ol>

        <h2 v-html="tmd('dbyloi_portal_product_body_10')"></h2>
        <p v-html="tmd('dbyloi_portal_product_body_11')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_12')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_13')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_14')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_15')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_16')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_17')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_18')"></p>
        <p v-html="tmd('dbyloi_portal_product_body_19')"></p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalProduct extends Vue {

}
</script>