
import { Options, Vue } from 'vue-class-component';

import Socials from "@/components/Socials.vue";

@Options({
    props: {
    },
    components: {
        Socials
    }
})
export default class Footer extends Vue {

}
