
import { Options, Vue } from 'vue-class-component';

import Socials from "@/components/Socials.vue";

@Options({
    props: {
        show: Boolean,
    },
    components: {
        Socials
    },
    emits: [
        'close', 'navigate'
    ],
})
export default class Menu extends Vue {

    show = false;
    hidden = true;

    mounted()
    {
        this.$watch('show', (show: boolean) => {
            if(show) this.hidden = false;
        });
    }

    public closeMenu()
    {
        this.hidden = true;

        setTimeout(() => {
            this.$emit('close');
        }, 500);
    }

    private handleNav(anchor: string)
    {
        this.closeMenu()

        setTimeout(() => {
            this.$emit('navigate', anchor);
        }, 300);
    }

}
