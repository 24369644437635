
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        white:Boolean,
        whitebordered:Boolean,
        blue:Boolean,
        icon:String,
        link:String,
        target:String,
    }
})
export default class CTAButton extends Vue {

    private white?: boolean;
    private whitebordered?: boolean;
    private blue?: boolean;
    private icon?: string;
    private link?: string;
    private target?: string;

    mounted()
    {
        console.log(this.icon);
    }

    getIcon()
    {
        if(this.icon) return require(`@/assets/app-images/svgs/${this.icon}.svg`);
    }

    getLink()
    {
        if(this.link) return this.link;
    }

}
