import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import Home from '../views/Home.vue'
import MainTemplate from '../views/MainTemplate.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

import PortalHome from '../components/Portal/PortalHome.vue'
import PortalPlayers from '../components/Portal/PortalPlayers.vue'
import PortalProduct from '../components/Portal/PortalProduct.vue'
import PortalOrganisations from '../components/Portal/PortalOrganisations.vue'
import PortalPGSolutions from '../components/Portal/PortalPGSolutions.vue'
import PortalThankYou from '../components/Portal/PortalThankYou.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: MainTemplate
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: PrivacyPolicy
  },
  {
    path: '/portal',
    name: 'portal;',
    component: () => import(/* webpackChunkName: "about" */ '../views/Portal.vue'),
    children: [
      {
        path: '',
        name: 'portal-home',
        component: PortalHome
      },
      {
        path: 'players',
        name: 'portal-players',
        component: PortalPlayers
      },
      {
        path: 'product',
        name: 'portal-product',
        component: PortalProduct
      },
      {
        path: 'organisations',
        name: 'portal-organisations',
        component: PortalOrganisations
      },
      {
        path: 'pgsolutions',
        name: 'portal-pgsolutions',
        component: PortalPGSolutions
      },
      {
        path: 'thankyou',
        name: 'portal-thankyou',
        component: PortalThankYou
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
