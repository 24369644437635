
import { Options, Vue } from 'vue-class-component';


@Options({
  props: {
    msg: String
  },
  components: {
  }
})
export default class PrivacyPolicy extends Vue {

}
