
import { Options, Vue } from 'vue-class-component';

import CTAButton from '@/components/common/CTAButton.vue';

@Options({
    props: {
    },
    components: {
        CTAButton
    }
})
export default class PortalLink extends Vue {

}
