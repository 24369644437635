<template>
    <div class="portal-body__pgsolutions">
        <h1 v-html="tmd('dbyloi_generic_portal_header_pgs')"></h1>
        <p v-html="tmd('dbyloi_portal_pgs_body_1')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_2')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_3')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_4')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_5')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_6')"></p>
        <p v-html="tmd('dbyloi_portal_pgs_body_7')"></p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalPGSolutions extends Vue {

}
</script>