<template>
    <div class="helping-you">
        <a id="more-help" class="anchorx2" />
        <div class="helping-you__wrapper">
            <h2 class="helping-you__header">HELPING YOU.<br />WHEN YOU NEED IT MOST</h2>
            <p class="helping-you__body">{{ $t('dbyloi_site_helpingyou_body_1') }}</p>
            <img class="helping-you__logo" src="@/assets/app-images/svgs/dbyloi-no-strap.svg" />
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class HelpingYou extends Vue {

}
</script>