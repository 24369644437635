<template>
    <div class="portal-body__home">
        <h1 v-html="tmd('dbyloi_generic_portal_header_main')"></h1>
        <p v-html="tmd('dbyloi_portal_frontpage_body_1')"></p>
        <p v-html="tmd('dbyloi_portal_frontpage_body_2')"></p>
        <p v-html="tmd('dbyloi_portal_frontpage_body_3')"></p>
        <ul>
            <li>Transparency and integrity.</li>
            <li>To explain the concept and development of the product.</li>
            <li>To demonstrate what can be achieved by working with Lived-Experience.</li>
        </ul>
        <p v-html="tmd('dbyloi_portal_frontpage_body_4')"></p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalHome extends Vue {

}
</script>