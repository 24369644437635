/**
 * Loads in YAML files, and optionally strips out trans:: keys
 */
export default class YamlService
{
    // eslint-disable-next-line
    public static stripTrans(v: any)
    {
        return JSON.parse(JSON.stringify(v).replace(/trans::/g,""));
    }
}