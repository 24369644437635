<template>
    <div class="header">
        <div class="header__top-bar">
            <div class="header__top-bar-inner">
                <div class="header__menu">
                    <a @click.prevent="toggleMenu" href="#"><img v-svg-inline src="@/assets/menu.svg" /></a>
                </div>
                <div class="header__langauge-selector">
                    <img v-svg-inline src="@/assets/app-images/svgs/dbyloi-logo.svg" />
                </div>
            </div>
        </div>
        <img v-if="!isMobile()" class="header__logo" src="@/assets/app-images/svgs/desktop-logo.svg" />
        <img v-if="isMobile()" class="header__logo" src="@/assets/app-images/svgs/dbyloi-no-strap.svg" />
        <p class="header__title">{{ $t('dbyloi_site_header_header') }}</p>
        <Teleport to="#overlay">
            <Menu :show="showMenu" @close="showMenu=false" @navigate="handleNavigate" />
        </Teleport>
    </div>
</template>

<script lang="ts">
import { Options, Vue} from 'vue-class-component';

// import { Teleport } from 'vue';

import Menu from '@/components/Menu.vue';

@Options({
    props: {
    }, components: {
        Menu
    },
    emits: ['navigate']
})
export default class Header extends Vue {

    private showMenu = false;

    public toggleMenu()
    {
        this.showMenu = !this.showMenu;
    }

    private handleNavigate(anchor: string)
    {
        this.$emit('navigate', anchor);
    }

}
</script>