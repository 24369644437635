import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './sass/style.scss';

import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "vue-svg-inline-plugin/src/polyfills";


import { createI18n } from 'vue-i18n';
import en from '@/data/i18n/en.json';

import mitt from 'mitt';

import { Vue } from 'vue-class-component';

interface Trans {
    [key: string]: string;
}

const enNew: Trans = {};
const enOrig: Trans = en;

Object.keys(en).map((key) => {
    const newKey: string = key.replace(/^trans::/, '');
    enNew[newKey] = enOrig[key] as string;
});


const emitter = mitt();

const app = createApp(App);

app.use(router).mount('#app')
app.use(VueSvgInlinePlugin);

app.config.globalProperties.$emitter = emitter;


const i18n = createI18n({
    locale: 'en',
    messages: {
        en: enNew,
    }
});
app.use(i18n);

console.log(enNew);

app.mixin({
    methods: {
        isMobile: function () {
            if(typeof getComputedStyle === "function")
            {
                const value = getComputedStyle(document.documentElement).getPropertyValue('--media-mobile-max');
                const media = window.matchMedia(`(max-width: ${value})`);

                if(media && media.matches) return true;
            }

            return false;
        },
        tmd: function(key: string) {

            const txt = i18n.global.t(key);

            const bold = /\*\*(.*?)\*\*/gm;
            const italic = /\*(.*?)\*/gm;
            return txt.replace(bold, '<strong>$1</strong>').replace(italic, '<i>$1</i>');
        }
    }
});