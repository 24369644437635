<template>
    <div class="help-recovery__wrapper">
        <a id="self-help" class="anchor" />
        <h2 class="header-block help-recovery__header">{{ $t('dbyloi_site_helprecovery_header') }}</h2>
        <div class="help-recovery__icons">
            <div class="help-recovery__icon-icon" v-for="(help, i) in helps" :key="i">
                <div class="help-recovery__icon-container">
                    <span class="icon">
                        <img v-svg-inline :class="help.class" :src="getImage(help.icon)" />
                    </span>
                    <h5><span v-html="help.title" /></h5>
                    <p><span v-html="help.copy" /></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class HelpRecovery extends Vue {

    data()
    {
        return {
            helps: [
                { title: this.$t('dbyloi_site_helprecovery_icon_1'), class: "sleep",        icon: "sleep.svg",          copy: this.$t('dbyloi_site_helprecovery_text_1') },
                { title: this.$t('dbyloi_site_helprecovery_icon_2'), class: "eat",          icon: "eat.svg",            copy: this.$t('dbyloi_site_helprecovery_text_2') },
                { title: this.$t('dbyloi_site_helprecovery_icon_3'), class: "exercise",     icon: "exercise.svg",       copy: this.$t('dbyloi_site_helprecovery_text_3') },
                { title: this.$t('dbyloi_site_helprecovery_icon_4'), class: "something",    icon: "do-something.svg",   copy: this.$t('dbyloi_site_helprecovery_text_4') },
            ]
        }
    }

    getImage(icon: string)
    {
        return require(`@/assets/app-images/svgs/${icon}`);
    }

}
</script>