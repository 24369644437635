<template>
    <div class="portal-body__players">
        <h1 v-html="tmd('dbyloi_generic_portal_header_players')"></h1>
        <h2 v-html="tmd('dbyloi_portal_players_players_subheader')"></h2>
        <p v-html="tmd('dbyloi_portal_players_body_1')"></p>
        <p v-html="tmd('dbyloi_portal_players_body_2')"></p>
        <p v-html="tmd('dbyloi_portal_players_body_3')"></p>
        <ul>
            <li>For new players Getting Started – how to keep on track and stay safe.</li>
            <li>For anyone Needing Help – how to recognise problems earlier and faster, make better choices and improve outcomes.</li>
            <li>What to do when you need to stop.</li>
        </ul>
        <p v-html="tmd('dbyloi_portal_players_body_4')"></p>
        <p v-html="tmd('dbyloi_portal_players_body_5')"></p>
        <p v-html="tmd('dbyloi_portal_players_body_6')"></p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalPlayers extends Vue {

}
</script>