
import { Options, Vue} from 'vue-class-component';

// import { Teleport } from 'vue';

import Menu from '@/components/Menu.vue';

@Options({
    props: {
    }, components: {
        Menu
    },
    emits: ['navigate']
})
export default class Header extends Vue {

    private showMenu = false;

    public toggleMenu()
    {
        this.showMenu = !this.showMenu;
    }

    private handleNavigate(anchor: string)
    {
        this.$emit('navigate', anchor);
    }

}
