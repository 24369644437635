
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class HelpRecovery extends Vue {

    data()
    {
        return {
            helps: [
                { title: this.$t('dbyloi_site_helprecovery_icon_1'), class: "sleep",        icon: "sleep.svg",          copy: this.$t('dbyloi_site_helprecovery_text_1') },
                { title: this.$t('dbyloi_site_helprecovery_icon_2'), class: "eat",          icon: "eat.svg",            copy: this.$t('dbyloi_site_helprecovery_text_2') },
                { title: this.$t('dbyloi_site_helprecovery_icon_3'), class: "exercise",     icon: "exercise.svg",       copy: this.$t('dbyloi_site_helprecovery_text_3') },
                { title: this.$t('dbyloi_site_helprecovery_icon_4'), class: "something",    icon: "do-something.svg",   copy: this.$t('dbyloi_site_helprecovery_text_4') },
            ]
        }
    }

    getImage(icon: string)
    {
        return require(`@/assets/app-images/svgs/${icon}`);
    }

}
