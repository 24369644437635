
import { Options, Vue } from 'vue-class-component';

import CTAButton from '@/components/common/CTAButton.vue';
import Socials from "@/components/Socials.vue";

@Options({
    props: {
    },
    components: {
        CTAButton, Socials
    }
})
export default class Contact extends Vue {

}
