<template>
    <div class="portal-body__thankyou">
        <h1 v-html="tmd('dbyloi_generic_portal_header_thank_you')"></h1>
        <p v-html="tmd('dbyloi_portal_thank_you_body_1')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_2')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_3')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_4')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_5')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_6')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_7')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_8')"></p>
        <p v-html="tmd('dbyloi_portal_thank_you_body_9')"></p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalThankYou extends Vue {

}
</script>