
import { Options, Vue } from 'vue-class-component';
import Socials from "@/components/Socials.vue";

import CTAButton from '@/components/common/CTAButton.vue';

@Options({
    props: {
        letter: String,
        title: String,
        subtitle: String,
        body: String,
        answer: String,
        active: Boolean,
        playing: Boolean,
        controls: Boolean,
    },
    components: {
        CTAButton, Socials
    },
    emits: [
        'prev', 'playpause', 'next', 'share'
    ]
})
export default class AlphabetPanel extends Vue {

    private letter?: string;
    private title?: string;
    private subtitle?: string;
    private body?: string;
    private answer?: string;
    private active?: boolean;
    private playing?: boolean;
    private controls?: boolean;

    private isSharing = false;

    private prev()
    {
        this.$emit('prev');
    }

    private playpause()
    {
        this.$emit('playpause');
    }
    private next()
    {
        this.$emit('next');
    }

    private onSamaritans()
    {
        window.open('https://www.samaritans.org', '_blank');
    }

    private showShare(state: boolean)
    {
        this.isSharing = state;
    }

    private getShareUrl(): string
    {
        return `https://www.dontbetyourlifeonit.co.uk/#${this.letter}`;
    }

    private shareTwitter()
    {
        const base = `https://twitter.com/intent/tweet`;
        const page = this.getShareUrl();
        const text = `${this.$t(this.title)} - ${this.$t(this.subtitle)}`;
        const tags = `DontBetYOURLIFEOnIt,SaferGambling`;
        const params = `url=${encodeURIComponent(page)}&text=${encodeURIComponent(text)}&hashtags=${encodeURIComponent(tags)}`;

        const url = `${base}?${params}`;

        window.open(url, '_blank');
    }

    private shareFacebook()
    {

        const base = 'https://www.facebook.com/sharer.php';
        const page = this.getShareUrl();
        const text = `${this.$t(this.title)} - ${this.$t(this.subtitle)} #DontBetYOURLIFEOnIt #SaferGambling`;
        const params = `u=${encodeURIComponent(page)}&quote=${encodeURIComponent(text)}`;

        const url = `${base}?${params}`;

        window.open(url, '_blank');
    }

    get playText(): string
    {
        if(this.playing) return this.$t('dbyloi_site_alphabettool_playing_button');

        return this.$t('dbyloi_generic_play_cta');
    }

    get playPauseSrc()
    {
        if(this.playing) return require("@/assets/app-images/svgs/pause.svg");

        return require("@/assets/app-images/svgs/play-sound.svg");
    }

}
