<template>
    <div class="contact">
        <a id="contact" class="anchor" />
        <div class="contact__wrapper">
            <div class="contact__we-are-listening">
                <p class="contact__we-are-listening-header">Your feedback is really important to us:</p>
                <p>Share your thoughts on Social Media</p>
                <Socials twitter facebook youtube class="contact__socials" />
            </div>
            <p class="portal-link__contact-us">or Contact Us</p>
            <div class="portal-link__contact-button">
                <CTAButton icon="scrub-forward"><a href="mailto:pgsolutions@dbyloi.co.uk?subject=Don't Bet Your Life On It">Email</a></CTAButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import CTAButton from '@/components/common/CTAButton.vue';
import Socials from "@/components/Socials.vue";

@Options({
    props: {
    },
    components: {
        CTAButton, Socials
    }
})
export default class Contact extends Vue {

}
</script>