
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        twitter: Boolean,
        facebook: Boolean,
        youtube: Boolean,
        sharing: Boolean,
    },
    emits: [
        'share-twitter', 'share-facebook', 'share-youtube'
    ]
})
export default class Socials extends Vue {

    private twitter?: boolean;
    private facebook?: boolean;
    private youtube?: boolean;
    private sharing?: boolean;

    private shareTwitter()
    {
        if(this.sharing)
        {
            this.$emit('share-twitter');
        } else {
            window.open('https://twitter.com/PGSolutions1', '_blank');
        }
    }

    private shareFacebook()
    {
        if(this.sharing)
        {
            this.$emit('share-facebook');
        } else {
            window.open('https://www.facebook.com/DontBetYourLifeOnIt', '_blank');
        }
    }

    private shareYoutube()
    {
        if(this.sharing)
        {
            this.$emit('share-youtube');
        } else {
            window.open('https://www.youtube.com/channel/UCfT2QXiq_4XWJr_Zqtet5kA', '_blank');
        }
    }

}
