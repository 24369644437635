<template>
    <div class="portal-body__organisations">
        <h1 v-html="tmd('dbyloi_generic_portal_header_organisations')"></h1>
        <p v-html="tmd('dbyloi_portal_organisations_body_1')"></p>

        <h2 v-html="tmd('dbyloi_portal_organisations_header_1')"></h2>

        <p v-html="tmd('dbyloi_portal_organisations_body_2')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_3')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_4')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_5')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_6')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_7')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_8')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_9')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_10')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_11')"></p>
        <p v-html="tmd('dbyloi_portal_organisations_body_12')"></p>

        <h2 v-html="tmd('dbyloi_portal_organisations_header_2')"></h2>
        <p>Contact: <a href="mailto:pgsolutions@dbyloi.co.uk">pgsolutions@dbyloi.co.uk</a></p>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    }
})
export default class PortalOrganisations extends Vue {

}
</script>