
import { Options, Vue } from 'vue-class-component';

import CTAButton from '@/components/common/CTAButton.vue';

// Exposed by SoundCloud API
// eslint-disable-next-line
declare const SC: any;

@Options({
    props: {
    },
    components: {
        CTAButton
    }
})
export default class HowtoStop extends Vue {

    // eslint-disable-next-line
    private widget: any;

    private playing = false;

    public mounted()
    {
        const widgetIframe = document.getElementById('sc-player-how-to-stop');
        const widget = SC.Widget(widgetIframe);

        this.widget = widget;

        widget.bind(SC.Widget.Events.PLAY , () => {
            this.playing = true;
            this.$emitter.emit('globalPlay', 'AndFinally');
        });

        widget.bind(SC.Widget.Events.PAUSE , () => {
            this.playing = false;
        });

       this.$emitter.on('globalPlay', this.handleGlobalPlay);
    }

    private handleGlobalPlay(target: string)
    {
        if(target !== 'AndFinally')
        {
            // Pause self
            this.widget.pause();
        }
    }

    public beforeUnmount()
    {
        this.$emitter.off('globalPlay', this.handleGlobalPlay);
    }

    private format(key: string)
    {
        const str = this.$t(key);
        const bold = /\*\*(.*?)\*\*/gm;
        return str.replace(bold, '<strong>$1</strong>');
    }

    public onPlay()
    {
        this.playing = !this.playing;

        if(this.playing)
        {
            this.widget.play();
        } else {
            this.widget.pause();
        }
    }

}
